import React, { FC, useState } from 'react';
import { PageProps } from 'gatsby';
import { PageLayout, DropBox, Stepper, Accordion, FileViewTable } from '../components';
import { Seo } from '../components';
import { FileFormatType, FAQType } from '../typedefs';
import { GetAppTwoTone, MoveToInboxOutlined } from '@material-ui/icons/';
import { Typography } from '@material-ui/core';
import { FileStateType } from '../typedefs';
import { showAlert } from '../utils/generalFunctions';

export interface PageContext {
    title: string,
    slug: string,
    indexing: boolean,
    metaTitle: string,
    metaDescription: string,
    inputFile: FileFormatType,
    outputFile: FileFormatType,
    content: any,
    faq: FAQType[],
};

const ConvertionSteps = React.memo(() => (
    <Stepper
        iconsLables={[
            { Icon: MoveToInboxOutlined, label: (<><h3>Step 1</h3><p>Drag & Drop or Browse your desired file in the above box</p></>) },
            { Icon: GetAppTwoTone, label: (<><h3>Step 2</h3><p>Download your file</p></>) },
        ]}
    />))

/////////////////////////////////////////// Functional components ////////////////////////////////////////
const FileTofileConverter: FC<PageProps<{}, PageContext, {}>> = ({ pageContext, path }) => {
    const { title, slug, indexing, metaTitle, metaDescription, inputFile, outputFile, content, faq } = pageContext;
    const [filesState, setFilesState] = useState<FileStateType[]>([]);

    const handleFileStateRemove = (id: number) => {
        const newState = filesState.filter((st) => st.id !== id)
        setFilesState([...newState])
    };

    const addNewFiles = (files: File[]) => {
        files.forEach((file) => {
            filesState.push({
                id: filesState.slice(-1)[0] ? (filesState.slice(-1)[0].id + 1) : 1,
                file: file,
                remoteFileName: null,
                fileUrl: null,
                isFetching: false,
                fetchingAction: null,
                errorMsg: null,
                isError: false,
            })
        })
        setFilesState([...filesState])
    }

    return (
        <PageLayout path={path}>
            <Seo title={metaTitle} propMetaDescription={metaDescription} />
            <h1>{title}</h1>
            <DropBox
                onFileReceive={addNewFiles}
                filterFiles={[inputFile]}
                onWrongFileReceive={(filename) => { showAlert(`${filename} cannot be convert`, 'error') }}
            />
            <br />
            <ConvertionSteps />
            <br /><br />
            <FileViewTable
                filesDetailsArray={filesState}
                onRowRemove={handleFileStateRemove}
                defaultConvertFrmt={outputFile.extension}
            />
            <br /><br />
            <div style={{ display: faq.length ? undefined : "none" }}>
                <Typography variant='h5' >Frequently Asked Questions (FAQ)</Typography>
                <br />
                <div>{faq.map(({ a, q }, idx) => <Accordion key={idx} title={q} collapse={a} />)}</div>
            </div>

        </PageLayout>
    )
}

export default FileTofileConverter;
